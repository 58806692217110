import UI from './UI';

export default class Sidebar {
  constructor(pageState) {
    this.navbarEl = document.querySelector('#navbar');
    this.navbarContainer = document.querySelector('#navbar > .container');

    this.sidebarEl = document.createElement('div');
    // TODO: more classes based on open property
    this.sidebarEl.setAttribute('class', 'sidenav-wrapper');

    this.sidebarNavEl = document.createElement('nav');
    this.sidebarNavEl.setAttribute('class', 'app-sidebar-navigation');

    this.sidebarUlEl = document.createElement('ul');

    this.sidebarNavEl.appendChild(this.sidebarUlEl);
    this.sidebarEl.appendChild(this.sidebarNavEl);

    this.sidebarOpenBtn = document.createElement('button');
    this.sidebarOpenBtn.setAttribute(
      'class',
      'btn btn-sm clear-items btn-secondary px-3 d-lg-none user-menu-button'
    );

    this.sideBarOpenArrow = document.createElement('i');

    this.sideBarOpenArrow.setAttribute('class', 'fas fa-chevron-double-right');

    this.sidebarOpenBtn.appendChild(this.sideBarOpenArrow);

    this.pageState = pageState;
    const { t, isModuleEnabled, isCurrentUserApproved } = pageState;

    this.sidebarLinks = [
      {
        href: '/app/dashboard',
        iconClass: 'fas fa-th-large',
        text: () => t('sidebar_link_dashboard', 'My Dashboard'),
      },
      {
        href: '/app/participants/profile',
        iconClass: 'fas fa-user',
        text: () => t('sidebar_link_profile', 'My Personal Profile'),
      },
      {
        href: () => {
          // use different link based on if the current user belongs to an organisation
          return this.pageState.currentUser?.organisation_profile?.id
            ? '/app/organisation/profile'
            : '/app/organisation/create';
        },
        iconClass: 'fas fa-briefcase',
        text: () => t('sidebar_link_organisation', 'My Organisation Profile'),
        shouldRender: () => isModuleEnabled('Organisation'),
      },
      {
        href: '/app/marketplace/list',
        iconClass: 'fas fa-store-alt',
        text: () => t('sidebar_link_marketplace', 'My Marketplace'),
        shouldRender: () => isModuleEnabled('Marketplace'),
      },
      {
        href: '/app/requests',
        iconClass: 'fas fa-user-plus',
        text: () => t('sidebar_link_requests', 'Requests'),
        shouldRender: () => isCurrentUserApproved() && isModuleEnabled('Meeting'),
      },
      {
        href: '/app/schedule',
        iconClass: 'fas fa-calendar-alt',
        text: () => t('sidebar_link_scheduling', 'Scheduling'),
        shouldRender: () => isCurrentUserApproved() && isModuleEnabled('Meeting'),
      },
      {
        href: '/app/agenda',
        iconClass: 'fas fa-clock',
        text: () => t('sidebar_link_agenda', 'Agenda'),
        shouldRender: () => isCurrentUserApproved() && isModuleEnabled('Agenda'),
      },
      {
        href: '/app/search/participants',
        iconClass: 'fas fa-search',
        text: () => t('sidebar_link_search', 'Search'),
        shouldRender: () => isCurrentUserApproved(),
      },
      {
        href: '/app/bookmarks',
        iconClass: 'fas fa-bookmark',
        text: () => t('sidebar_link_bookmarks', 'My Bookmarks'),
        shouldRender: () => isCurrentUserApproved() && isModuleEnabled('Bookmark'),
      },
      {
        id: 'sidebarMsgLink',
        href: '/app/messages',
        iconClass: 'fas fa-comment-alt-dots',
        text: () => t('sidebar_link_messages', 'My Messages'),
        shouldRender: () => isCurrentUserApproved() && isModuleEnabled('Messages'),
      },
    ];

    this.toggleSideBar = this.toggleSideBar.bind(this);
  }

  renderUnreadMessagesIndicator() {
    if (
      this.pageState.isModuleEnabled('Messages') &&
      this.pageState.currentUser.new_messages_exists
    ) {
      const msgSidebarLink = document.getElementById('sidebarMsgLink');
      msgSidebarLink?.classList.add('unread');
    }
  }

  //   toggleNavbarSide() {
  //     this.isSideBarOpen = !this.isSideBarOpen;
  //     const sideBarArrowIcon = document.querySelector('.user-menu-button > i');
  //     const sideBar = document.querySelector('.sidenav-wrapper');
  //     if (this.isSideBarOpen) {
  //       sideBar.classList.add('opened');
  //       sideBarArrowIcon.classList.remove('fa-chevron-double-right');
  //       sideBarArrowIcon.classList.add('fa-chevron-double-left');
  //     } else {
  //       sideBar.classList.remove('opened');
  //       sideBarArrowIcon.classList.add('fa-chevron-double-right');
  //       sideBarArrowIcon.classList.remove('fa-chevron-double-left');
  //     }
  //   }

  setToggleListener(cb) {
    this.sidebarOpenBtn.onclick = cb;
  }

  toggleSideBar() {
    const sideBarArrowIcon = document.querySelector('.user-menu-button > i');

    if (this.pageState.isSidebarOpen) {
      this.sidebarEl.classList.add('opened');
      sideBarArrowIcon.classList.remove('fa-chevron-double-right');
      sideBarArrowIcon.classList.add('fa-chevron-double-left');
    } else {
      this.sidebarEl.classList.remove('opened');
      sideBarArrowIcon.classList.add('fa-chevron-double-right');
      sideBarArrowIcon.classList.remove('fa-chevron-double-left');
    }
  }

  render() {
    if (!this.pageState.currentUser) {
      UI.hide(this.sidebarEl);
      UI.hide(this.sidebarOpenBtn);
      return;
    }

    // make sidebar container element visible if it was hidden previously
    UI.show(this.sidebarEl);
    UI.show(this.sidebarOpenBtn);
    // render the sidebar after the navbar
    this.navbarEl.parentNode.insertBefore(this.sidebarEl, this.navbarEl.nextSibling);
    this.navbarContainer.insertBefore(this.sidebarOpenBtn, this.navbarContainer.firstChild);
    // render sidebar links
    this.sidebarUlEl.innerHTML = '';
    this.sidebarLinks.forEach(linkData => {
      if (linkData.hasOwnProperty('shouldRender') && !linkData.shouldRender()) {
        return;
      }

      const sidebarLink = UI.createSidebarLink(linkData);
      this.sidebarUlEl.appendChild(sidebarLink);
    });

    this.renderUnreadMessagesIndicator();
  }
}
